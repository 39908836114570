import { MenuConfigType } from '../types/Config';
import { ProgramItemType, ProgramContentType, ProgramTaskType } from '../types/Program';


export const example_tasks_content : ProgramTaskType[] = [
  { id: 10201, item_id: 102, title: '', difficulty: '1', content: 
    'Create a variable with numeric value 5 in JavaScript. Print variable type using "type" command.'
  },
  { id: 10202, item_id: 102, title: '', difficulty: '1', content: 
    'Create a variable with text value “Hello world” in JavaScript. Print variable type using "type" command.'
  },
  { id: 10203, item_id: 102, title: '', difficulty: '1', content: 
    'Create two numeric variables with values 5 and 7. Make a third variable which is a product of the first two values. Print the third variable.'
  },
  { id: 10204, item_id: 102, title: '', difficulty: '1', content: 
    'Create two text variables “Hello world” and “!”. Make a third variable which is equal to these variables concatenated. Print the third variable.'
  },


  { id: 10400, item_id: 104, title: '', difficulty: '1', content: ``},
  { id: 10401, item_id: 104, title: '', difficulty: '2', content: ``},
  { id: 10402, item_id: 104, title: '', difficulty: '2', content:
    'Generate a random number between 0 and 10 and assign it to a variable "randomNumber". If the number is greater than 7, print "Group 1" to console. If the number is between 3 and 7, print "Group 2". Otherwise print "Group 3".'
  },

  { id: 10901, item_id: 109, title: '', difficulty: '1', content: 
    'Write a for loop to print numbers from 1 to 20.'
  },
  { id: 10902, item_id: 109, title: '', difficulty: '1', parent_id: 10420, content: 
    'Write a for loop to print the squares of the first 10 positive integers.'
  },
  { id: 10903, item_id: 109, title: '', difficulty: '2', parent_id: 10420, content: 
    'Write a for loop to print the first 10 powers of 2 (e.g., 2^1, 2^2, 2^3, ..., 2^10)'
  },
  { id: 10904, item_id: 109, title: '', difficulty: '1', content: 
    'Write a for loop to print numbers from 10 down to 1 in reverse order.'
  },
  { id: 10905, item_id: 109, title: '', difficulty: '2', content: 
    'Write a for loop to calculate the sum of numbers from 1 to 10.'
  },
  { id: 10906, item_id: 109, title: '', difficulty: '2', content: 
    'Write a for loop to print all odd numbers between 1 and 20.'
  },
  { id: 10907, item_id: 109, title: '', difficulty: '2', content: ``},



  { id: 10593, item_id: 105, title: '', difficulty: '1', content: 
    'Make a function "greetUser" that takes one parameter userName and <b>returns</b> "Hello " + userName. <br> Run the function and print the result to console.'
  },

  { id: 10594, item_id: 105, title: '', difficulty: '1', content: 
    'Make a function "multiplyByTwo" that takes a number and <b>returns</b> this number multiplied by 2.  '
  },
  { id: 10595, item_id: 105, title: '', difficulty: '1', parent_id: 10594, content: 
    "Make a function that takes a number and <b>returns</b> its value squared"
  },

  { id: 10501, item_id: 105, title: '', difficulty: '1', content: 
    'Make a function "multiplyNumbers" that takes two numbers and <b>returns</b> their product.'
  },
  { id: 10471, item_id: 105, title: '', difficulty: '1', parent_id: 10501, content: 
    'Make a function "sumTwoNumbers" that takes two numbers and returns a sum of these numbers.'
  },
  { id: 10472, item_id: 105, title: '', difficulty: '1', parent_id: 10501, content: 
    'Make a function that takes three numbers and returns a sum of these numbers.'
  },

  { id: 10451, item_id: 105, title: '', difficulty: '1', content: ``},
  { id: 10452, item_id: 105, title: '', difficulty: '1', parent_id: 10451, content: ``},
  { id: 10453, item_id: 105, title: '', difficulty: '1', parent_id: 10451, content: ``},

  { id: 10475, item_id: 105, title: '', difficulty: '2', content: 
    'Make a function that takes a number that represents date of birth, and returns the corresponding age.'
  },
  { id: 10476, item_id: 105, title: '', difficulty: '2', parent_id: 10475, content: 
    'Make a function that takes a number that represents age in years, and returns the corresponding birth year.'
  },

  { id: 10473, item_id: 105, title: '', difficulty: '2', content: 
    'Make a function that takes three numbers and returns the average of these numbers. <br>(Hint: average is a sum of all values divided by the number of them.)'
  },
  { id: 10474, item_id: 105, title: '', difficulty: '2', content: 
    'Make a function that takes two strings and returns them concatenated with a space between.'
  },
  { id: 10477, item_id: 105, title: '', difficulty: '2', content: 
    'Make a function that takes a decimal number and returns its value rounded. <br> Hint: use Math.round() function ([w3](https://www.w3schools.com/jsref/jsref_round.asp))'
  },


  { id: 10571, item_id: 105, title: '', difficulty: '2', content: 
    'Make a function that generates (returns) a random number between 0 and 1. <br>Hint: see [w3](https://www.w3schools.com/js/js_random.asp)'
  },
  { id: 10572, item_id: 105, title: '', difficulty: '2', parent_id: 10571, content: 
    'Make a function that generates (returns) a random number between 0 and 10.'
  },
  { id: 10573, item_id: 105, title: '', difficulty: '2', parent_id: 10571, content: 
    'Make a function that takes a numeric value and generates (returns) a random number between 0 and the given number.'
  },
  { id: 10573, item_id: 105, title: '', difficulty: '3', parent_id: 10571, content: 
    'Create a function that takes two numbers and generates a random number between a given range.'
  },


  { id: 10595, item_id: 105, title: '', difficulty: '2', content: 
    "Make a function that takes a number and returns true if the number is positive and false if it's negative or zero."
  },
  { id: 10471, item_id: 105, title: '', difficulty: '2', parent_id: 10595, content: 
    'Make a function that takes two numbers and returns the larger of these numbers.'
  },
  { id: 10471, item_id: 105, title: '', difficulty: '2', parent_id: 10595, content: 
    'Make a function “checkNumberOverflow” that will return “true” if a number is above 1000000 and “false” otherwise (let’s say we need to check data before sending to server). <br>Example: checkNumberOverflow(123) should return false.'
  },
  { id: 10471, item_id: 105, title: '', difficulty: '2', parent_id: 10595, content: 
    'Make a function “divideNumbers” that will take two numbers and return a result of a division of two numbers. Return “false” if denominator is 0.'
  },
  { id: 10471, item_id: 105, title: '', difficulty: '2', parent_id: 10595, content: 
    'Make a function "getOddEven" that will take a number and return a string "odd" if the number is odd, and "even” otherwise. '
  },
  { id: 10471, item_id: 105, title: '', difficulty: '2', parent_id: 10595, content: 
    'Make a function "getOddEven2" that will take a number and return a string "odd" if the number is odd, and "even” otherwise. <br>In addition, return string "error" if the number is not integer.'
  },

  { id: 10561, item_id: 105, title: '', difficulty: '1', content: ''},
  { id: 10565, item_id: 105, title: '', difficulty: '1', parent_id: 10561, content: ''},
  { id: 10566, item_id: 105, title: '', difficulty: '1', parent_id: 10561, content: ''},
  { id: 10563, item_id: 105, title: '', difficulty: '1', parent_id: 10561, content: ''},

  { id: 10581, item_id: 105, title: '', difficulty: '3', content: 
    'Make a function that sums the digits of a given number.'
  },

  { id: 10478, item_id: 106, title: '', difficulty: '1', content: 
    'Make a function that takes two strings and returns a concatenated value of these strings. '
  },
  { id: 10479, item_id: 106, title: '', difficulty: '1', content: 
    'Make a function that takes a string and returns the length of this string. '
  },
  { id: 10480, item_id: 106, title: '', difficulty: '1', content: 
    'Make a function that takes a string and returns the first character of the string.'
  },
  { id: 10549, item_id: 106, title: '', difficulty: '2', content: 
    'Make a function that takes a string and returns true if the string contains character “a”.'
  },
  { id: 10550, item_id: 106, title: '', difficulty: '2', content: 
    'Make a function that takes two strings and returns "true" if the first string contains the second one.'
  },
  { id: 10551, item_id: 106, title: '', difficulty: '2', content: 
    'Make a function that will return extension for a given file name. File name example is "image01.png"'
  },
  { id: 10552, item_id: 106, title: '', difficulty: '2', content: 
    'Make a function "formatNumber" that takes a number (between 1 and 99) and returns a formatted string. Formatting rules: add "0" in front of the number if it is < 10, so the string length is always 2.'
  },
  { id: 10553, item_id: 106, title: '', difficulty: '3', content: 
    'Make a function “formatNumbersToTime” that creates a string from 3 parameters - hours, minutes and seconds. For example, formatNumbersToTime(2, 15, 5) should return “02:15:05”. <br>(You can use the previous task to add “0” to numbers below 10)'
  },
  { id: 10631, item_id: 106, title: '', difficulty: '3', content: 
    'Make a function that reverses a word.'
  },
  { id: 10631, item_id: 106, title: '', difficulty: '3', content: 
    'Make a function that counts the number of vowels in a string.'
  },
  { id: 10631, item_id: 106, title: '', difficulty: '3', content: 
    'Make a function that takes a string of numbers separated by commas and returns the sum of those numbers.'
  },

  

  { id: 10701, item_id: 107, title: '', difficulty: '1', content: 
    'Create an object that has 2 properties: "userFullname" with value "John Doe" and "jobTitle" with value "Project Manager". '
  },
  { id: 10702, item_id: 107, title: '', difficulty: '1', parent_id: 10301, content: 
    'Create an object that has 3 properties: "userID" with the value "jdoe", "userLastName" with value "Doe" and "userFirstName" with value "John". '
  },

  { id: 10703, item_id: 107, title: '', difficulty: '1', content: 
    'Create a variable "userObject" with value { userID: "jdoe", userFullname: "John Doe", age: 40 }. Print "userID" property to console. Print "age" property to console. '
  },
  { id: 10704, item_id: 107, title: '', difficulty: '1', parent_id: 10703, content: 
    'Create a variable "dog" with value { name: "Patches", breed: "spaniel", age: 3 }. Print "dog" property to console. Print "breed" property to console. '
  },
  { id: 10705, item_id: 107, title: '', difficulty: '1', parent_id: 10703, content: 
    'Create a variable "address" with value { streetName: "Broadway", streetNumber: 19, zip: "02138" }. Combine all properties into one-line address and print it to console. '
  },
  { id: 10706, item_id: 107, title: '', difficulty: '1', parent_id: 10703, content: 
    'Create a variable "purchase" with value { name: "Milk", quantity: 2, cost: 2.5 }. Calculate total cost using object properties and print it to console. '
  },
  { id: 10707, item_id: 107, title: '', difficulty: '1', parent_id: 10703, content: 
    'Create a variable "rectangle" with value { width: 7, length: 9, color: "green" }. Calculate rectangle area using object properties and print it to console. '
  },

  { id: 10708, item_id: 107, title: '', difficulty: '1', content: 
    'Create an empty object with name "person". Add properties "age" and "name" with some values to the existing object. '
  },
  { id: 10709, item_id: 107, title: '', difficulty: '1', parent_id: 10708, content: 
    'Create a variable "dog" with value { name: "Bear", age: 4 }. Add properties "breed" and "height" with some values to the existing object. '
  },

  { id: 10521, item_id: 107, title: '', difficulty: '2', content: 
    'Make a function "getUserFullname" that takes an object and returns the "userFullname" property. <br>Object example: { userID: "jdoe", userFullname: "John Doe", jobTitle: "Project Manager" }  '
  },
  { id: 10522, item_id: 107, title: '', difficulty: '2', content: 
    'Make a function "getUserAge" that takes an object and returns "age" property when age is <150, and a message "Input error" otherwise.<br>Object example: { userID: "jdoe", userFullname: "John Doe", age: 20 } '
  },
  { id: 10523, item_id: 107, title: '', difficulty: '2', content: 
    'Make a function "getUserFullnameOrID" that takes an object and returns either “userFullname” property when it is not empty, and "userID" otherwise. <br>Object example: { userID: "jdoe", userFullname: "John Doe", jobTitle: "Project Manager" } '
  },
  { id: 10524, item_id: 107, title: '', difficulty: '2', content: 
    'Make a function “getTotalCost” that takes an object and returns total cost based on “unitCost” and “quantity” properties of a given object. <br> Object example: { unitCost: 15, quantity: 3, purchaseID: "001", comments: “road sign” }  '
  },
  { id: 10525, item_id: 107, title: '', difficulty: '2', content: 
    'Make a function “getTotalCost” that takes an object and <b>adds "totalCost" property</b> to it, based on “unitCost” and “quantity” properties of a given object. <br> Object example: { unitCost: 15, quantity: 3, purchaseID: "001", comments: “road sign” }  '
  },
  { id: 10526, item_id: 107, title: '', difficulty: '1', content: 
    'Make a function “getObjectKeys” that takes an object and returns a list of its keys. <br>Example: it would return an array [“userID”, “userFullname”, “age”] for object { userID: “jdoe”, userFullname: “John Doe”, age: “23” }.'
  },
  { id: 10527, item_id: 107, title: '', difficulty: '1', content: 
    'Make a function “getObjectValues” that takes an object and returns a list of its keys. <br>Example: it would return an array ["jdoe", "John Doe", "23"] for object { userID: “jdoe”, userFullname: “John Doe”, age: “23” }. '
  },
  { id: 10528, item_id: 107, title: '', difficulty: '3', content: 
    'Make a function "getUserProperty" that takes a property name and an object, and returns a given property for a given object. <br> Example:  getUserProperty("jobTitle", { userID: "jdoe", jobTitle: "Project Manager" }) should return "Project Manager"'
  },


  { id: 10801, item_id: 108, title: '', difficulty: '1', content:
    'Create two arrays and combine them into a single array using "concat" method.'
  },
  { id: 10802, item_id: 108, title: '', difficulty: '1', content: 
    'Create an array of your five favorite colors and use a for loop to print each color on a new line.'
  },
  { id: 10803, item_id: 108, title: '', difficulty: '1', parent_id: 10802,  content: 
    'Create an array of numbers (e.g [5, 12, 7, 18, 3, 25]). Use a loop to prints only the numbers greater than a 10.'
  },
  { id: 10804, item_id: 108, title: '', difficulty: '2', content: 
    'Create an array of numbers and use a loop to calculate and print the sum of all numbers in the array.'
  },
  { id: 10805, item_id: 108, title: '', difficulty: '2', content: 
    'Create an array of numbers and use a loop to print the elements of an array in reverse order.'
  },
  { id: 10806, item_id: 108, title: '', difficulty: '2', content: 
    'Create an array of strings (e.g ["Apple", "Orange", "Banana", "Cherry"]). Use a loop to replace a specific element (e.g., "Cherry") in with another value (e.g., "Grape"). '
  },
  
  { id: 10513, item_id: 108, title: '', difficulty: '1', content: 
    'Make a function that will return the length of an array. Return “false” if argument is not an array.'
  },
  { id: 10514, item_id: 108, title: '', difficulty: '1', content: ''},
  { id: 10515, item_id: 108, title: '', difficulty: '2', content: ''},
  { id: 10516, item_id: 108, title: '', difficulty: '2', parent_id: 10515, content: ``},
  { id: 10518, item_id: 108, title: '', difficulty: '2', parent_id: 10515, content: ``},
  { id: 10512, item_id: 108, title: '', difficulty: '2', content: ``},
  { id: 10511, item_id: 108, title: '', difficulty: '2', parent_id: 10512, content: ``},
  { id: 10517, item_id: 108, title: '', difficulty: '3', content: ``},
  { id: 10519, item_id: 108, title: '', difficulty: '3', content: ``},

  { id: 10830, item_id: 108, title: '', difficulty: '3', content: 
    'Make a function that takes an array and a value, and returns the number of times the value appears in the array.'
  },
  { id: 10831, item_id: 108, title: '', difficulty: '3', content: 
    'Make a function that removes duplicate elements from an array.'
  },
  { id: 10821, item_id: 108, title: '', difficulty: '1', content: 
    'Make a function that sorts an array of strings in ascending order.'
  },
  { id: 10822, item_id: 108, title: '', difficulty: '2', content: 
    'Make a function that sorts an array of numbers in ascending order.'
  },
  { id: 10832, item_id: 108, title: '', difficulty: '3', content: 
    'Make a function that sorts an array of strings by their length.'
  },
  { id: 10833, item_id: 108, title: '', difficulty: '3', content: 
    'Make a function that takes two numeric values and returns an array containing all numbers between these values.'
  },

  
  // functions with no return
  { id: 11101, item_id: 111, title: '', difficulty: '1', content: 
    'Make a function "printHello" that prints "Hello world" to console. Run the function 3 times. '
  },
  { id: 11102, item_id: 111, title: '', difficulty: '1', content: 
    'Make a function "printHelloUser" that  takes one parameter userName and prints "Hello " + userName to console. <br> Run the function with different names.' 
  },
  { id: 11102, item_id: 111, title: '', difficulty: '1', content: 
    'Make a function "printMultiplyByTwo" that takes a number and prints this number multiplied by 2 to console. ' 
  },

  // functions basic + objects
  { id: 10562, item_id: 111, title: '', difficulty: '1', content: ''},
  { id: 10564, item_id: 11, title: '', difficulty: '1', parent_id: 10562, content: ''},

  { id: 11110, item_id: 111, title: '', difficulty: '1', content: 
    'Make a function that takes two numbers and <b>prints</b> to console “First number is greater” if the first number is greater than the second one. Print “Second number is greater” otherwise.'
  },

  { id: 11113, item_id: 111, title: '', difficulty: '2', content: 
    'Make a function that converts Fahrenheit to Celsius.' 
  },

  { id: 11120, item_id: 111, title: '', difficulty: '3', content: 
    'Make a function to check if a string is a palindrome.' 
  },
  { id: 11121, item_id: 111, title: '', difficulty: '2', content: 
    'Make a  function to count the number of words in a sentence.' 
  },
  { id: 11122, item_id: 111, title: '', difficulty: '3', content: 
    'Make a function that counts the number of vowels in a string.' 
  },
  { id: 11123, item_id: 111, title: '', difficulty: '3', content: 
    'Make a function to capitalize the first letter of each word in a string.' 
  },
  { id: 11124, item_id: 111, title: '', difficulty: '3', content: 
    'Make a function to find the longest word in a sentence.' 
  },
  { id: 11125, item_id: 111, title: '', difficulty: '3', content: 
    'Make a function to count the occurrences of each character in a string.' 
  },

  { id: 11131, item_id: 111, title: '', difficulty: '2', content: 
    'Make a function that removes all falsy values (false, null, 0, undefined, NaN, "") from an array.' 
  },
  { id: 11132, item_id: 111, title: '', difficulty: '2', content: 
    'Make a function that counts the occurrences of a specific element in an array.' 
  },
  { id: 11133, item_id: 111, title: '', difficulty: '3', content: 
    'Make a function to get all unique values from two arrays.' 
  },
  { id: 11134, item_id: 111, title: '', difficulty: '3', content: 
    'Make a function to flatten a nested array.' 
  },

  { id: 11140, item_id: 111, title: '', difficulty: '3', content: 
    'Make a function to find the intersection of two arrays.'
  },
  { id: 11141, item_id: 111, title: '', difficulty: '3', parent_id: 11140, content: 
    'Make a function that checks if two arrays have the same elements (order does not matter).'
  },
  { id: 11142, item_id: 111, title: '', difficulty: '3', content: 
    'Make a function that finds the second largest number in an array.'
  },
  { id: 11143, item_id: 111, title: '', difficulty: '4', content: 
    'Make a function to find the missing numbers in a sequence.'
  },
  { id: 11144, item_id: 111, title: '', difficulty: '4', content: 
    'Make a function that rotates the elements of an array by a given number of positions.'
  },
  { id: 11145, item_id: 111, title: '', difficulty: '3', content: 
    'Make a function to find the median of an array of numbers.'
  },

  { id: 11151, item_id: 111, title: '', difficulty: '2', content: 
    'Make a function that converts an object into an array of key-value pairs.'
  },
  { id: 11152, item_id: 111, title: '', difficulty: '3', content: 
    'Make a function to flatten a deeply nested object into a single level.'
  },


  { id: 20301, item_id: 203, title: '', difficulty: '1', content: 
    'Create an HTML page. Add a text paragraph ("p" tab). Add different headers ("h1" - "h6" tags). <br> Check indentation for all elements to make sure it reflects hierarchy of the elements.'
  },
  { id: 20302, item_id: 203, title: '', difficulty: '1', content: 
    'Add a link inside the text paragraph ("a" tag, any url). <br>Check indentation for all elements to make sure it reflects hierarchy of the elements.'
  },
  { id: 20303, item_id: 203, title: '', difficulty: '1', content: 
    'Change the code from the previous task so all headers are wrapped in a div, and paragraph stays where it was. <br>Check indentation for all elements to make sure it reflects hierarchy of the elements.'
  },
  { id: 20304, item_id: 203, title: '', difficulty: '1', content: ``},
  { id: 20305, item_id: 203, title: '', difficulty: '1', parent_id: 20304, content: ``},
  { id: 20306, item_id: 203, title: '', difficulty: '1', parent_id: 20304, content: ``},



  { id: 20401, item_id: 204, title: '', difficulty: '1', content: 
    'Add styles.css file to your project folder and link it in HTML file. Change the body background color to "lightblue" or any other color.'
  },

  { id: 20402, item_id: 204, title: '', difficulty: '1', content: 
    'Overwrite default margin/padding on body element - set it to 0. '
  },

  { id: 20403, item_id: 204, title: '', difficulty: '1', content: 
    'Add a "div" to your HTML file with a class "colored-bkg" and some text inside. Change the background color of the div using class selector. '
  },

  { id: 20404, item_id: 204, title: '', difficulty: '1', content: 
    'Add another "div" with class "colored-text" and some text. Change color of the text. Add 1rem padding.'
  },
  { id: 20405, item_id: 204, title: '', difficulty: '1', content: 
    'Add a "div" and give it a class with any name. Set div height to 200px and width to 300px. Add background color to see the div on the page. Add 20px margin.'
  },

  { id: 20406, item_id: 204, title: '', difficulty: '1', content: 
    'Add a paragraph ("p" tag) to the page and give it a class with any name. Make text inside the paragraph bold, also change font size to 1.2rem and line spacing to 2rem (google "CSS line spacing" to find the property name).'
  },
  { id: 20407, item_id: 204, title: '', difficulty: '1', content: 
    'Add div to the page, move it to top right corner using "top", "right" and "position" properties.'
  },

  { id: 20310, item_id: 204, title: '', difficulty: '1', content: 
    'Add div element with "class" and "id" attributes. Add padding to the div using class attribute, and background color using id attribute.'
  },
  { id: 20310, item_id: 204, title: '', difficulty: '1', content: 
    'Which type of selector would you use to change style for all "p" elements on the page? <br> Which type of selector would you use to change style for one "p" element?'
  },



  { id: 20701, item_id: 207, title: '', difficulty: '2', completed_date: '1', content: 
    'Add a div with width 40vw and height 40vh. Center it vertically and horizontally on the page using top and left css properties. <br>(Create a new page for this task or a parent div for this task.) '
  },
  { id: 20702, item_id: 207, title: '', difficulty: '2', completed_date: '1', content: 
    'Add a div with width 40vw and height 40vh. Center it vertically and horizontally on the page using margin. <br>(Create a new page for this task or a parent div for this task.) '
  },
  { id: 20703, item_id: 207, title: '', difficulty: '2', content: 
    'Add a div with width 40vw and height 40vh. Center it vertically and horizontally on the page using flex properties on the parent element. (Use cheatsheet to find solution with flexbox) <br>(Create a new page for this task or a parent div for this task.) '
  },

  { id: 20704, item_id: 207, title: '', difficulty: '2', content: 
    'Add 3 divs with 20vw width. Display them inline. (Create a new page or a parent div for this task)'
  },
  { id: 20705, item_id: 207, title: '', difficulty: '2', content: 
    'Add 4 divs and display them inline, so each takes a fourth of the screen width and they always feet no matter screen size. <br> (Create a new page or a parent div for this task)'
  },

  { id: 20706, item_id: 207, title: '', difficulty: '2', content: 
    'Add a div with a text inside (width 50vw height 50vh). Center text inside div horizontally using text-align.'
  },
  { id: 20707, item_id: 207, title: '', difficulty: '2', content: 
    'Add a div with a text inside (width 50vw height 50vh). Center text inside the div horizontally + vertically using "display:flex".'
  },

  { id: 20708, item_id: 207, title: '', difficulty: '2', imageUrl: 'https://devclass-program.s3.us-east-2.amazonaws.com/task_github_button.png',
    content: 
    "Create a button with an logo icon and a text. Center icon and text vertical as shown in the picture. <br> (Hint: padding won't work for this element, because icon and text have different height. See the hints for centering text vertically+horizontally)."
  },
  
  { id: 20709, item_id: 207, title: '', difficulty: '2', content:
    'Create a div for a top bar element. Add 4 divs with text links - 2 on the left and 2 on the right. Center text in all divs vertically and horizontally so it all shows on the same line.'
  },


  { id: 20731, item_id: 207, title: '', content: '', difficulty: '2', imageUrl: 'https://devclass-program.s3.us-east-2.amazonaws.com/task_basichtml.png' },
  { id: 20732, item_id: 207, title: '', content: '', difficulty: '3', imageUrl: 'https://devclass-program.s3.us-east-2.amazonaws.com/image2.png' },
  { id: 20733, item_id: 207, title: '', content: '', difficulty: '3', imageUrl: 'https://devclass-program.s3.us-east-2.amazonaws.com/image_4.png' },
  { id: 20734, item_id: 207, title: '', content: '', difficulty: '3', imageUrl: 'https://devclass-program.s3.us-east-2.amazonaws.com/image_3.png' },




  
  { id: 22201, item_id: 222, title: '', difficulty: '1', content: 
    'Add a div element with id="welcome-message" and text "Original text" in HTML. Change its inner text to "Hello, World!" using JavaScript.'
  },
  { id: 22202, item_id: 222, title: '', difficulty: '1', content:
    'Add a div with id="styled-div" and some text inside in HTML. Change its background color to blue and text color to white using JavaScript.'
  },
  { id: 22203, item_id: 222, title: '', difficulty: '1', content:
    'Add a div with id="container" in HTML. Dynamically create a new "p" element with some text and append it to the div (using JavaScript).'
  },
  { id: 22204, item_id: 222, title: '', difficulty: '1', content: 
    'Add an element with id="remove-me" in HTML. Remove it from the DOM using JavaScript.'
  },
  { id: 22210, item_id: 222, title: '', difficulty: '2', content: 
    'Add a button with id="toggle-button" in HTML. <br> In JavaScript, add "onclick" event to the button so it prints "Toggle" to console when clicked. '
  },
  { id: 22211, item_id: 222, title: '', difficulty: '2', content: 
    'Add a button with id="toggle-color" and a "p" element with id="colored-text" in HTML. <br> In JavaScript, add "onclick" event to the button so it changes the color of the text in "p" element when clicked. '
  },
  { id: 22212, item_id: 222, title: '', difficulty: '2', content: 
    'Add a button with id="append" and a div with id="container" in HTML. <br>Add JavaScript logic to add "p" element to the div when the button is clicked. You can put any text inside "p" element to make it visible.'
  },
  { id: 22213, item_id: 222, title: '', difficulty: '2', content: 
    'Add an input field and a button to HTML. Write JavaScript code to print the text from the input field when the button is clicked.'
  },

  { id: 22220, item_id: 222, title: '', difficulty: '3', content: 
    'Create an input field with id="user-input", and a button. Display the input text in a div when user types. <br> (Hint: check "oninput" and "onchange" events)'
  },
  { id: 22221, item_id: 222, title: '', difficulty: '3', content: ``, imageUrl: 'https://devclass-program.s3.us-east-2.amazonaws.com/practice_message_board.png'},
  { id: 22222, item_id: 222, title: '', difficulty: '3', content: ``, imageUrl: 'https://devclass-program.s3.us-east-2.amazonaws.com/task_collapse.png'},
  { id: 22223, item_id: 222, title: '', difficulty: '3', content: ``, imageUrl: 'https://devclass-program.s3.us-east-2.amazonaws.com/task_search.png'},
  { id: 22224, item_id: 222, title: '', difficulty: '3', content: 
    'Add a button with id="hover-button". Change its text when the mouse is over and revert it back on "mouseout".'
  },
  { id: 22225, item_id: 222, title: '', difficulty: '3', content: ``, imageUrl: 'https://devclass-program.s3.us-east-2.amazonaws.com/task_todoitem.png'},



  { id: 30101, item_id: 301, title: '', difficulty: '1', content: 
    "Make a function that takes a person's name and age and returns a string (use template literals): <br>'Hi, my name is [name] and I am [age] years old.'  "
  },
  { id: 30102, item_id: 301, title: '', difficulty: '1', content: 
    'Make a function that takes two arrays as arguments and returns a new array that is the concatenation of the two arrays (use array destructuring). '
  },
  { id: 30103, item_id: 301, title: '', difficulty: '2', parent_id: 30102, content: 
    'Make a function that takes an array and a value and returns an updated array that contains the given value (use array destructuring). '
  },
  { id: 30104, item_id: 301, title: '', difficulty: '1', content: 
    'Make a function that takes two objects as arguments and returns a new object that combines the properties of both objects (use object destructuring). '
  },
  { id: 30105, item_id: 301, title: '', difficulty: '2', content: 
    "Make a function that takes an array of numbers, and returns a new array with elements that are greater than 10 (use array's filter method with arrow function). "
  },

  { id: 30111, item_id: 301, title: '', difficulty: '2', content: 
    'Make a function that takes an array of numbers and returns a new array where each number is doubled. (use array map method with arrow function). '
  },
  { id: 30112, item_id: 301, title: '', difficulty: '2', content: ``},
  { id: 30115, item_id: 301, title: '', difficulty: '2', parent_id: 30112, content: ``},
  { id: 30117, item_id: 301, title: '', difficulty: '2', parent_id: 30112, content: ``},
  { id: 30121, item_id: 301, title: '', difficulty: '2', parent_id: 30112, content: ``},

  { id: 30113, item_id: 301, title: '', difficulty: '2', content: ``},
  { id: 30114, item_id: 301, title: '', difficulty: '2', parent_id: 30113, content: ``},
  { id: 30116, item_id: 301, title: '', difficulty: '2', parent_id: 30113, content: ``},
  { id: 30119, item_id: 301, title: '', difficulty: '2', parent_id: 30113, content: ``},
  { id: 30120, item_id: 301, title: '', difficulty: '2', parent_id: 30113, content: ``},

  { id: 30118, item_id: 301, title: '', difficulty: '2', content: ``},

  { id: 30131, item_id: 301, title: '', difficulty: '2', content: 
    'Make a function that takes an array of objects that represents address, and returns an array of strings (one-line addresses). Address object example: { streetName: "Broadway", streetNumber: 449, zip: "02139" } '
  },
  { id: 30132, item_id: 301, title: '', difficulty: '2', content: 
    'Make a function that takes an array of strings and returns a new array containing only strings with 5 characters or more and converts them to uppercase.'
  },
  /*
  { id: 30133, item_id: 301, title: '', difficulty: '2', content: `
    Make a function that takes an array of people and returns a filtered array containing only people age 21 and older. 
    Array example: [
  { name: "Alice", age: 25 },
  { name: "Bob", age: 17 },
  { name: "Charlie", age: 35 }
    ];
  `},
  */

  { id: 30141, item_id: 301, title: '', difficulty: '2', content: 
    'Make a function performOperation that accepts two numbers and a callback function, and executes the callback with those numbers rounded.'
  },
  { id: 30142, item_id: 301, title: '', difficulty: '2', parent_id: 30141, content: 
    'Make a function delayedOperation that accepts a callback, and executes it after a 2-second delay using setTimeout.'
  },

  { id: 30143, item_id: 301, title: '', difficulty: '2', content: 
    'Make a function processArray that takes an array and a callback, and applies the callback to each element of the array.'
  },
  { id: 30144, item_id: 301, title: '', difficulty: '2', parent_id: 30143, content: 
    "Make a function filterArray that takes an array and a callback, and filters the array based on the callback's condition."
  },
  { id: 30145, item_id: 301, title: '', difficulty: '2', parent_id: 30143, content: 
    "Make a function transformString that takes a string and a callback, and uses the callback to transform each character in the string."
  },


  { id: 30401, item_id: 304, title: '', difficulty: '2', content: 
    'Make a React component that displays "Hello, username" text for a given username.'
  },
  { id: 30404, item_id: 304, title: '', difficulty: '2', content: 
    'Create a ProfileCard component: <br>- Props: name (string), age (number), bio (string). <br>- Display the name, age, and bio in the card.'
  },
  { id: 30405, item_id: 304, title: '', difficulty: '2', content: ''
  },


  { id: 30501, item_id: 305, title: '', difficulty: '2', content: 
    '(Conditional rendering)<br>Create a component "LoginStatus":<br> - Props: isLoggedIn (boolean).<br>- Render: "Welcome Back" or "Please Log In" based on a boolean state.'
  },
  { id: 30502, item_id: 305, title: '', difficulty: '2', content: 
    '(Conditional rendering)<br>Create a TemperatureIndicator component: <br> - Props: temperature (number). <br> - Render: "Hot" in red if temperature > 30, "Warm" in orange if temperature > 15 && temperature <= 30, "Cold" in blue if temperature <= 15.'
  },
  { id: 30521, item_id: 305, title: '', difficulty: '2', content: ''
  },
  { id: 30522, item_id: 305, title: '', difficulty: '3', content: ''
  },
  { id: 30541, item_id: 305, title: '', difficulty: '4', content: 
    'Build a navigation bar with three links (e.g., Home, About, Contact). Show the active link with a different style. (Use React Router)'
  },

  { id: 30801, item_id: 308, title: '', difficulty: '3', content: 
    'Create a component that shows "Logged In" or "Logged Out" based on a boolean state. Add a button to toggle the state.'
  },
  { id: 30802, item_id: 308, title: '', difficulty: '3', content: 
    'Create a "Collapse" component with a text paragraph and a button that toggles the visibility of the paragraph. '
  },
  { id: 30803, item_id: 308, title: '', difficulty: '3', content: 
    'Create a Counter component: <br> - Use state to track the count. <br> - Include buttons to increase and decrease the count. <br> - Render count and a message: "Count is zero" when the count is 0, "Positive count" when the count is greater than 0, "Negative count" when the count is less than 0.'
  },
  { id: 30804, item_id: 308, title: '', difficulty: '3', content: 
    'Create a DataLoader component.<br> - Props: isLoading (boolean), data (string). <br> - Render: "Loading..." when isLoading is true; the data content when isLoading is false and data is available; "No data available" when isLoading is false and data is empty or null.'
  },



  { id: 40501, item_id: 405, title: '', difficulty: '2', imageUrl: 'https://devclass-program.s3.us-east-2.amazonaws.com/task_appapi.png', content: 
    'Make a simple page with JS/HTML/CSS with one input field and a button. Send a request to the server with the message on button click. ' 
  },

];

const imageMdExample = `
    <div style="width:100%;object-fit:cover;" class="markdown-image_fullwidth">
<img width="100%" src="https://devclass-program.s3.us-east-2.amazonaws.com/image_5.png">
</div>
`;
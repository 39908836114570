import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { ProgramContentType, ProgramItemType, ProgramTaskType } from '../types/Program';
import { getProgramItemId } from '../utils/programUtils';
import { MarkdownBlock } from '../components/MarkdownBlock';
import './ProgramItem.css';
import { Collapse } from '../components/Collapse';
import { Icon } from '../components/Icon';


export function ProgramItem(props: { content: ProgramContentType, tasks: ProgramTaskType[] }) {
  const navigate = useNavigate();
  const { content, tasks } = props;

  const [markdownContent, setMarkdownContent] = useState('');

  useEffect(() => {
    // tmp: Fetch the Markdown file content
    const filePath = `/tmpMarkdown/${content.item_id}.md`;
    fetch(filePath)
      .then((response) => response.text())
      .then((text) => {
        if (text && text.indexOf('<!DOCTYPE html>') !== 0) setMarkdownContent(text)
      })
      .catch((error) => console.error('Error loading Markdown file:', error));
  }, [content]);

  function handleSelect(item: ProgramContentType) {
    const newUrl = `/program/${item.item_id}`;
    navigate(newUrl);
  }

  const itemTasks = tasks.filter(task => task.item_id === content.item_id && !task.parent_id);

  const clExtra = {
    'program-item__extra': 1,
    'program-item__extra_empty': !itemTasks.length,
  };

  return (
    <div className="program-item" id={'itemcontent' + content.item_id}>
      <div className="program-item__main">
        <div className="program-item__title" id={getProgramItemId(content.item_id)} onClick={() => handleSelect(content)}>
          {content.item_prefix} {content.title}
        </div>
        <div className="program-item__content">
          {/* content.content */}
          <MarkdownBlock>{markdownContent}</MarkdownBlock>
        </div>
      </div>
      <div className={classNames(clExtra)}>
        {itemTasks.map((task, index) => {
          const similarTasks = tasks.filter(rt => rt.parent_id === task.id);
          return (
            <>
              <ProgramTask task={task} taskCode={`${content.item_prefix}${index + 1}`} />
              {similarTasks.length > 0 && (
                <Collapse label="Similar tasks" className="collapse-similar">
                  {similarTasks.map((t, index2) => <ProgramTask task={t} taskCode={`${content.item_prefix}${index + 1}_${index2 + 2}`} hasParent />)}
                </Collapse>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
}

export function ProgramTask(props: { task: ProgramTaskType, taskCode: string, hasParent?: boolean }) {
  const { task, taskCode, hasParent = false } = props;
  const { content, id, item_id, secondaryContent, imageUrl, difficulty, completed_date } = task;

  const [markdownContent, setMarkdownContent] = useState('');
  const [markdownExtra, setMarkdownExtra] = useState('');

  const difficultyMap : { [key: string]: string } = {
    '1': 'basic',
    '2': '',
    '3': 'med',
    '4': 'advanced'
  };
  const difficultyDisplay = difficultyMap[difficulty || ''];
  let renderContent = content;
  if (renderContent.at(0) === '`') renderContent.replace('`', '');
  console.log('content:', renderContent, renderContent.at(0));

  useEffect(() => {
    // tmp: Fetch the Markdown file content
    const filePath = `/tmpMarkdownTasks/${id}.md`;
    fetch(filePath)
      .then((response) => response.text())
      .then((text) => {
        if (text && text.indexOf('<!DOCTYPE html>') !== 0) setMarkdownContent(text)
      })
      .catch((error) => console.error('Error loading Markdown file:', error));
    
    // tmp: Fetch the Markdown file content
    // doesn't work on server
    /*
    const filePathExtra = `/tmpMarkdownTasks/${id}_extra.md`;
    fetch(filePathExtra)
      .then((response) => response.text())
      .then((text) => {
        if (text && text.indexOf('<!DOCTYPE html>') !== 0) setMarkdownExtra(text)
      })
      .catch((error) => console.error('Error loading Markdown file:', error));
    */
  
  }, [content]);

  return (
    <div className="program-task">
      <div className="program-task__title">
        Task {taskCode} 
        {/* <span className="program-task__more">&#8942;</span> */}
        {/* completed_date && <Icon className="program-task__completed" name="done_all" /> */}
        {difficultyDisplay && <span className="program-task__difficulty">({difficultyDisplay})</span>}
      </div>
      <div className="program-task__content">
        <MarkdownBlock className='markdown-block_task'>{renderContent || markdownContent}</MarkdownBlock>
        {imageUrl && (
          <Collapse label="View image">
            <div className="markdown-image_fullwidth">
              <img width="100%" src={imageUrl} />
            </div>
          </Collapse>
        )}
        {markdownExtra && !hasParent && (
          <Collapse label="View details">
            <MarkdownBlock className='markdown-block_task'>{markdownExtra}</MarkdownBlock>
          </Collapse>
        )}
        {markdownExtra && hasParent && (
          <MarkdownBlock className='markdown-block_task'>{markdownExtra}</MarkdownBlock>
        )}
      </div>
    </div>
  );
}

export function ProgramSectionTitle(props: { item: ProgramItemType }) {
  const { item } = props;

  return (
    <div className="program-item">
      <div className="program-item__main">
        <div className="program-section-title" id={getProgramItemId(item.id)}>
          {item?.title}
        </div>
      </div>
      <div className="program-item__extra program-item__extra_empty">
      </div>
    </div>
  );
}
